<template>
  <div style="margin-top: 10px; margin-bottom: 10px; margin-left: 10px">
    <div style="float: left; display: flex; align-items: center">
      <el-button size="mini" @click="refresh" type="primary">刷新</el-button>
      <el-input
        size="mini"
        v-model="this.listQuery.name"
        style="width: 120px; margin-left: 10px"
        @keydown.enter="getList"
      ></el-input>
      <el-input
        size="mini"
        v-model="listQuery.notVersion"
        style="width: 120px; margin-left: 10px"
        placeholder="版本号"
      ></el-input>
      <el-button size="mini" @click="getList" type="primary">搜索</el-button>

      <el-select
        v-model="listQuery.status"
        class="m-2"
        placeholder="选择状态"
        size="mini"
        @change="getList"
        style="margin-left: 10px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-switch
        v-model="swith"
        style="margin-left: 20px"
        @change="switchchange"
        active-color="#13ce66"
        active-text="可部署"
        inactive-text="不可部署"
      ></el-switch>
      <el-button
        size="mini"
        @click="examine"
        type="primary"
        style="margin-left: 30px"
        :disabled="look_el"
        >查看</el-button
      >
      <el-dialog
        :before-close="look_el"
        v-model="show_dialog"
        title="正在部署的ops名称"
      >
        <div
          v-for="(item, index) in name_arr"
          :key="index"
          style="margin-top: 20px"
        >
          <span style="font-weight: bold">{{ item }}</span>
        </div>
      </el-dialog>
      <span class="spanSum">机器人总数:&nbsp;&nbsp;{{ RobotNum || 0 }}</span>
      <span class="spanSum">客户端总数:&nbsp;&nbsp;{{ clientNum || 0 }}</span>
    </div>
    <div style="float: right; margin-bottom: 10px; margin-right: 10px">
      <el-button
        :loading="loading"
        size="mini"
        type="primary"
        @click="allDeploy('deploy')"
        >部署并启动</el-button
      >
      <el-button size="mini" type="success" @click="allDeploy('check')"
        >检查</el-button
      >
      <el-button size="mini" type="primary" @click="allDeploy('start')"
        >启动</el-button
      >
      <el-button size="mini" type="warning" @click="allDeploy('send')"
        >部署不启动</el-button
      >
      <el-button
        size="mini"
        type="primary"
        @click="packes"
        :loading="loadingpack"
        >打包</el-button
      >
      <el-button size="mini" type="danger" @click="allDeploy('stop')"
        >停止</el-button
      >
      <!-- <el-button size="mini" type="success" @click="save">保存</el-button> -->
    </div>
  </div>
  <div class="box_div">
    <div class="left_box">
      <div class="header_div">
        <h3>部署日志</h3>
      </div>
      <div v-for="(item, index) in journal" :key="index">
        <button
          class="rz_text"
          @click="look(item.id, item)"
          :disabled="isDisable"
        >
          第{{ item.id }}次部署
        </button>
        <el-dialog
          :title="'第' + item.id + '次部署'"
          v-model="dislog"
          width="30%"
          :before-close="handleClose"
          v-if="flag == item.id"
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
            "
          >
          <div>
            <div>
              <span class="time">打包开始时间:</span
              ><span v-html="timefont" class="time"></span>
            </div>
            <div style="margin-top: 10px">
              <span class="time">打包结束时间:</span
              ><span v-html="timeoverfont" class="time"></span>
            </div>
          </div>
          <!-- <el-button type="primary" icon="el-icon-refresh-left" round class="refiresh" size="mini" :loading="refireshLoad" @click="clickRefiresh">刷新</el-button> -->
          </div>
          <p class="rz_textval" v-html="boxarr"></p>
        </el-dialog>
      </div>
    </div>
    <!--   -->
    <div class="right_box" :class="[shows == 1 ? 'activewidth' : '']">
      <!--  @selection-change="handleSelectionChange" -->
      <el-table
        :data="list"
        style="width: 100%"
        border
        :row-key="getDataId"
        ref="districtTable"
        :select-on-indeterminate="true"
        @selection-change="handleSelectionChange"
        :loading="isLoading"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @select-all="selectAll"
        @select="selectChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :reserve-selection="true"
        />
        <el-table-column prop="opsName" label="ops名称" width="180" />
        <el-table-column prop="robotName" label="机器人名称" width="230" />
        <el-table-column prop="path" label="路径" width="180" />
        <el-table-column prop="git" label="git地址" />
        <el-table-column prop="status" label="状态" width="80">
          <template #default="scope">
            <span v-if="scope.row.status == '1'" style="color: green"
              >进行中</span
            >
            <span v-if="scope.row.status == '0'" style="color: red"
              >未运行</span
            >
            <span v-if="scope.row.status == '3'" style="color: red"
              >未运行</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="updatedAt" label="时间">
          <template #default="scope">
            {{ $parseTime(scope.row.updatedAt, "{y}-{m}-{d} {h}:{i}:{s}") }}
          </template>
        </el-table-column>
        <el-table-column prop="stop" label="停止状态">
          <template #default="scope">
            <span v-if="scope.row.stop == '1'" style="color: red"
              >不可自动重启</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="version" label="版本号"> </el-table-column>
        <!-- <el-table-column align="center">
                    <el-button class='btn'>查看更多</el-button>
                </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  deployList,
  deploy,
  newsdeploy,
  newsdeploy2,
  newsdeployList,
  deployStatus,
  setDeployStatus,
  getOpsStatus,
  deployTax,
} from "@/api/deploy";
import { VueElement, vModelCheckbox } from "vue";
export default {
  name: "invoicing",
  data() {
    return {
      isDisable: false,
      flag: 0,
      path_route: "",
      // dialogTableVisible: false,
      list: [], //数据列表
      dislog: false,
      show_dialog: false,
      multipleSelection: [],
      isLoading: false,
      loading: false,
      refireshLoad:false,
      itemIds: [], //传递参数的数组
      checkedKeys: false,
      loadingpack: false,
      listQuery: {
        name: "",
        notVersion: "",
        status: 1,
      },
      swith: "true",
      options: [
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "未运行",
        },
        {
          value: 0,
          label: "全部",
        },
      ],
      idarr: [],
      journal: [], //日志列表
      bsid: "",
      childbsid: "",
      showhide: 0,
      timer: null,
      shows: 0,
      number: 0,
      choose_index: 0,
      boxarr: {},
      timefont: "",
      timeoverfont: "",
      afterArr: [],
      isAllSelect: false,
      falge: false,
      state: 0,
      name_arr: [],
      cleararr: [],
      RobotNum: 0,
      clientNum: 0,
    };
  },
  created() {
    this.getList();
    console.log(this.list.length);
  },
  mounted() {
    this.request();
    this.statusrequest();
  },
  watch: {},
  methods: {
    clickRefiresh(){//部署刷新
      this.refireshLoad=true
    },
    packes() {
      //打包
      this.loadingpack = true;
      let that = this;
      deployTax({}).then((res) => {
        that.request()
        if (res.data.errNo == 0) {
          let text=res.data.data.replace(/\n/g, "<br>");
          let arr=[text]
          let msg = arr.join('<br>')
          that.$message({
            dangerouslyUseHTMLString: true,
            message: msg,
            type: "success",
          });
          setTimeout(() => {
            that.loadingpack = false;
          }, 600);
        } else {
          console.log('111');
          // this.$qzfMessage(res.data.msg,1)
          // that.$message({
          //   message: res.data.msg,
          //   type: "warning",
          // });
          that.loadingpack = false;
        }
      });
    },
    examine() {
      console.log(this.multipleSelection.length);
      //点击查看
      this.show_dialog = true;
      getOpsStatus({}).then((res) => {
        if (res.data.errNo == 0) {
          this.name_arr = res.data.data;
        }
      });
    },
    switchchange(e) {
      // console.log(e);
      if (e == true) {
        this.state = 1;
        setDeployStatus({
          status: this.state,
        }).then((res) => {
          console.log(res);
          if (res.data.errNo == 0) {
            // this.$message({
            //  message: "保存成功",
            //  type: "success"
            // });
          }
        });
        // this.save(1)
      } else {
        this.state = 0;
        setDeployStatus({
          status: this.state,
        }).then((res) => {
          console.log(res);
          if (res.data.errNo == 0) {
            // this.$message({
            //  message: "保存失败",
            //  type: "warning"
            // });
          }
        });
      }
    },
    statusrequest() {
      deployStatus({}).then((res) => {
        if (res.data.errNo == 0) {
          this.state = res.data.data.status;
          if (this.state == 1) {
            this.swith = true;
          } else {
            this.swith = false;
          }
        }
      });
    },
    selectChange(selection, row) {
      let data = selection.some((item) => {
        return row.id === item.id;
      });
      if (data) {
        // 勾选节点时
        if (row.children) {
          this.setChildren(row.children, true);
        }
      } else {
        // 取消勾选节点时
        if (row.children) {
          this.setChildren(row.children, false);
        }
      }
    },
    setChildren(children, type) {
      children.map((item) => {
        this.toggleSelection(item, type);
        if (item.children) {
          this.setChildren(item.children, type);
        }
      });
    },
    getDataId(data) {
      return data["id"];
    },
    toggleRowSelection(selection, data) {
      console.log(selection);
      // console.log(this.multipleSelection);
      if (data.children) {
        //注意这里的children也是后台返回数据的children字段
        data.children.forEach((val) => {
          // console.log(val);
          this.$refs.districtTable.toggleSelection(val, false);
          if (val.children) {
            this.toggleRowSelection(selection, val);
          }
        });
      }
    },

    selectAll(selection) {
      // 全选
      let isSelect = selection.some((item) => {
        let tableDataIds = this.list.map((data) => data.id);
        if (tableDataIds.indexOf(item.id) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      // 全不选
      let isCancel = !this.list.every((item) => {
        let selectIds = selection.map((data) => data.id);
        if (selectIds.indexOf(item.id) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      if (isSelect) {
        selection.map((item) => {
          if (item.children) {
            this.setChildren(item.children, true);
          }
        });
      }
      if (isCancel) {
        this.list.map((item) => {
          if (item.children) {
            this.setChildren(item.children, false);
          }
        });
      }
    },
    toggleSelection(row, select) {
      if (row) {
        this.$nextTick(() => {
          this.$refs.districtTable.toggleRowSelection(row, select);
        });
      }
    },
    handleSelectionChange(val) {
      //勾选触发事件
      // this.multipleSelection = val;
      // this.multipleSelection.forEach((v) => {
      //   this.bsid = v.id;
      // });
      // console.log(val, "选中的值");
      this.multipleSelection = val;
      console.log(this.multipleSelection);
      this.multipleSelection.forEach((v) => {
        this.bsid = v.id;
      });
    },
    handleClose() {
      //关闭弹窗事件
      this.dislog = false;
      this.choose_index = 0;
      clearInterval(this.timer);
      this.timer = null;
    },
    look(index, item) {
      //点击某个部署发生的事件
      var that = this;
      that.isDisable = true;
      setTimeout(() => {
        that.isDisable = false; //点击一次时隔两秒后才能再次点击
      }, 600);
      that.dislog = true;
      that.flag = index;
      that.choose_index = 1;
      // that.request(index);
      that.timer = setInterval(() => {
        that.request(index);
      }, 600);
      this.showhide = 1;
    },
    request(id) {
      //实时日志中的接口数据
      var that = this;
      newsdeploy2({
        id: id || 0,
        page: 20,
        pagNum: 1,
      }).then((res) => {
        if (that.choose_index == 0) {
          that.journal = res.data.data.list;
        } else {
          that.boxarr = {};
          that.boxarr = res.data.data.list[0].log;
          that.boxarr = that.boxarr.replace(/\n/g, "<br>"); //替换
          var replace_text = ["失败", "超时"];
          var color_dict = { 失败: "rgb(255, 0, 0)", 超时: "rgb(255,0,0)" };
          for (var i = 0; i < replace_text.length; i++) {
            var color_temp = color_dict[replace_text[i]];
            var replaceString =
              '<span style="color: ' +
              color_temp +
              ';">' +
              replace_text[i] +
              "</span>";
            that.boxarr = that.boxarr.replace(
              RegExp(replace_text[i], "g"),
              replaceString
            );
          }
          let begintime = res.data.data.list[0].createdAt;
          let overtime = res.data.data.list[0].updatedAt;
          let oversj = String(overtime).substring(0, 10); //结束时间
          let overtimers = String(overtime).substring(11, 19); //结束时间
          let time = String(begintime).substring(0, 10); //开始时间
          let timeres = String(begintime).substring(11, 19); //开始时间
          that.timefont = time + "&nbsp;" + "&nbsp;" + timeres;
          that.timeoverfont = oversj + "&nbsp;" + "&nbsp;" + overtimers;
        }
        for (let i = 0; i < that.journal.length; i++) {
          let addbr = that.journal[i].log.replace(/\n/g, "<br>"); //替换
          that.journal[i].log = addbr;
        }
      });
    },
    getList() {
      //调用接口
      this.list = [];
      this.afterArr = [];
      this.isLoading = false;
      newsdeployList(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
          this.clientNum = this.list.length;
          let num = 0;
          console.log(this.list);
          this.list.forEach((element) => {
            num += element.children.length;
          });
          this.RobotNum = this.list.length + num;
          let tempArr = [];
          let that = this;
          for (let i = 0; i < this.list.length; i++) {
            if (tempArr.indexOf(this.list[i].opsName) == -1) {
              that.afterArr.push({
                name: this.list[i].opsName,
                origin: [this.list[i]],
              });
              tempArr.push(this.list[i].opsName);
            } else {
              for (let j = 0; j < that.afterArr.length; j++) {
                if (that.afterArr[j].name == this.list[i].opsName) {
                  that.afterArr[j].origin.push(this.list[i]);
                  break;
                }
              }
            }
          }
        } else {
          this.list = [];
        }
      });
    },
    refresh() {
      //刷新
      this.isLoading = true;
      this.$message({
        message: "刷新成功",
        type: "success",
      });
      this.getList();
      this.request();
    },
    allDeploy(val) {
      //点击部署或检查或停止按钮触发相应的接口
      if (val == "deploy") {
        this.choose_index = 0;
        this.loading = true;
        let idlist = [];
        this.multipleSelection.forEach((v) => {
          idlist.push(v.id);
        });
        newsdeploy({
          type: val,
          itemIds: idlist,
        }).then((res) => {
          var that = this;
          if (res.status == 200) {
            setTimeout(() => {
              that.loading = false;
            }, 600);
          }
        });
        var that = this;
        that.timer = setInterval(() => {
          that.request();
        }, 600);
        setTimeout(() => {
          clearInterval(that.timer);
          that.timer = null;
        }, 600);
        that.getList();
      }
      if (val == "check") {
        this.choose_index = 0;
        let idlist = [];
        this.multipleSelection.forEach((v) => {
          idlist.push(v.id);
        });
        newsdeploy({
          type: val,
          itemIds: idlist,
        }).then((res) => {
          var that = this;
          if (res.status == 200) {
            setTimeout(() => {
              that.loading = false;
            }, 600);
          }
        });
        var that = this;
        that.timer = setInterval(() => {
          that.request();
        }, 600);
        setTimeout(() => {
          clearInterval(that.timer);
          that.timer = null;
        }, 600);
        that.getList();
      } else if (val == "stop") {
        this.choose_index = 0;
        clearInterval(this.timer);
        this.timer = null;
        let idlist = [];
        this.multipleSelection.forEach((v) => {
          idlist.push(v.id);
        });
        newsdeploy({
          type: val,
          itemIds: idlist,
        }).then((res) => {
          var that = this;
          if (res.data.data) {
            this.$message({
              message: res.data.data,
              type: "success",
            });
            setTimeout(() => {
              that.loading = false;
            }, 600);
          } else {
            this.$message({
              message: "指令已发起,请稍后刷新查询",
              type: "success",
            });
          }
          // this.getList();
        });
        var that = this;
        that.timer = setInterval(() => {
          that.request();
        }, 600);
        setTimeout(() => {
          clearInterval(that.timer);
          that.timer = null;
        }, 600);
        // deploy({ type: val, itemIds: this.idarr }).then(res => {
        //     this.loading = false
        //     if (res.data.msg == "success") {
        //         if (res.data.data) {
        //             this.$message({
        //                 message: res.data.data,
        //                 type: "success"
        //             });
        //         } else {
        //             this.$message({
        //                 message: "指令已发起,请稍后刷新查询",
        //                 type: "success"
        //             });
        //         }
        //         this.getList();
        //     }
        // })
        that.getList();
      } else if (val == "start") {
        this.choose_index = 0;
        let idlist = [];
        this.multipleSelection.forEach((v) => {
          idlist.push(v.id);
        });
        newsdeploy({
          type: val,
          itemIds: idlist,
        }).then((res) => {
          var that = this;
          if (res.status == 200) {
            setTimeout(() => {
              that.loading = false;
            }, 600);
          }
        });
        var that = this;
        that.timer = setInterval(() => {
          that.request();
        }, 600);
        setTimeout(() => {
          clearInterval(that.timer);
          that.timer = null;
        }, 600);
        that.getList();
      } else if (val == "end") {
        this.choose_index = 0;
        let idlist = [];
        this.multipleSelection.forEach((v) => {
          idlist.push(v.id);
        });
        newsdeploy({
          type: val,
          itemIds: idlist,
        }).then((res) => {
          var that = this;
          if (res.status == 200) {
            setTimeout(() => {
              that.loading = false;
            }, 600);
          }
        });
        var that = this;
        that.timer = setInterval(() => {
          that.request();
        }, 600);
        setTimeout(() => {
          clearInterval(that.timer);
          that.timer = null;
        }, 600);
        that.getList();
      } else if (val == "send") {
        this.choose_index = 0;
        let idlist = [];
        this.multipleSelection.forEach((v) => {
          idlist.push(v.id);
        });
        newsdeploy({
          type: val,
          itemIds: idlist,
        }).then((res) => {
          var that = this;
          if (res.status == 200) {
            setTimeout(() => {
              that.loading = false;
            }, 600);
          }
        });
        var that = this;
        that.timer = setInterval(() => {
          that.request();
        }, 600);
        setTimeout(() => {
          clearInterval(that.timer);
          that.timer = null;
        }, 600);
        that.getList();
      }
    },
  },
};
</script>

<style>
.text_box .el-textarea {
  height: 100%;
}

.text_box .el-textarea__inner {
  min-height: 80px !important;
}

.el-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
<style lang="scss" scoped>
.big_box2 {
  width: 98%;
  padding: 15px 64px 40px 64px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 15px auto;
  // height: 890px;
  position: relative;
  border: 1px solid #ddd;

  .shuoming {
    color: red;
    position: absolute;
    top: 18px;
    left: 66px;
  }

  .arrow_btn {
    width: 20px;
    height: 60px;
    text-align: center;

    .two_btn {
      font-size: 20px;
      color: #f15a24;
      line-height: 60px;
      background: #eee;
    }

    .arrow_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .arrow_right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #008000;
  margin-top: 10px;
  border-right: none;
  // height: 684px;
}

.row_box2 {
  width: 100%;
  // height: 200px;
}

.grid-content {
  position: relative;
  min-height: 38px;
  font-size: 15px;
  color: #666;
  line-height: 38px;
  text-align: center;
}

.height_font {
  // writing-mode: vertical-lr;
  // padding: 0 15px;
  width: 100%;
  height: 156px;
  margin: 0 auto;
  text-align: center;
  background-color: whitesmoke;

  p {
    width: 20px;
    margin: 0 auto;
    height: 100%;
    line-height: 32px;
    padding-top: 14px;
  }
}

p {
  font-size: 14px;
}

.content {
  .el-row {
    width: 100%;
    // border:1px solid #eee;
  }

  .el-col {
    border-right: 1px solid #008000;
    border-bottom: 1px solid #008000;
  }

  .bg-purple {
    text-align: center;
    // position: relative;
  }

  .special .el-col {
    border-right: 1px solid #d56400;
  }
}

.list_info {
  line-height: 24px;

  &.red li {
    border-bottom: 1px solid #d56400 !important;
  }

  li {
    position: relative;
    line-height: 38px;
    height: 38px;
    font-size: 13px;
    color: #333;

    &:last-child {
      border-bottom: none;
    }

    input {
      width: 100%;
      margin: 0 auto;
      height: 36px;
      line-height: 36px;
      font-size: 13px;
      color: #333;
      background: none;
      border: none;
      padding: 0 5px;
    }
  }
}

.bottom_ad {
  float: left;
}

.name_box {
  text-align: center;
  font-size: 24px;

  h2 {
    margin: 0;
    font-family: cursive;
    letter-spacing: 4px;
    color: #008000;
    border-bottom: 4px double green;
    display: inline-block;
  }
}

.right {
  position: absolute;
  right: 50px;
  top: 120px;
}

.left {
  position: relative;
  top: 20px;
  left: 10px;
  width: 860px;
}

.el-form-item--mini.el-form-item {
  margin-bottom: 5px;
}

.el-form {
  margin: 10px 12px;
}

.el-textarea {
  // width: 94%;
  // margin: 10px 10px 10px 10px;
}

.home {
  margin-bottom: -6px;
  margin-top: 11px;
  padding: 0 !important;
}

.top {
  padding: 15px 20px 0 12px;

  p {
    float: left;
    font-size: 13px;
    color: #333;
    padding: 0;
    margin: 0;
    line-height: 28px;
  }
}

.common_item {
  height: 40px;
  line-height: 40px;

  p {
    font-size: 13px;
    color: #333;
    margin: 0;
  }
}

.rz_text {
  font-size: 17px;
  line-height: 40px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  font-size: 16px;
  line-height: 40px;
  cursor: pointer;
  font-weight: bold;
  // margin-left: 20px;
  margin-top: 20px;
  width: 120px;
  background-color: #6abfba;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  border: none;
}

.rz_textval {
  font-size: 17px;
  display: block !important;
  margin-top: 30px;
}

.box_div {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  // width: 100%;
}

.left_box {
  border: 1px solid #6abfba;
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 20px;
}

.header_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h3 {
  margin-top: 20px;
  // margin-left: 30px;
  color: #8a8a8a;
}

.close_png {
  width: 15px;
  height: 15px;
  margin-right: 20px;
  margin-top: 15px;
  cursor: pointer;
}

.active {
  width: 100%;
}

.right_box {
  width: 90%;
}

.activewidth {
  width: 90%;
}

.time {
  font-size: 17px;
  font-weight: bold;
}
.btn {
  border-radius: 50px;
}
.spanSum {
  font-size: 14px;
  margin-left: 20px;
  font-weight: 550;
}
.refiresh{
  height: 20px;
}
</style>
