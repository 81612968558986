import request from '@/utils/request'

//发票列表
export function deployList(data) {
  return request({
    url: 'api/v1/deployList',
    method: 'post',
    data
  })
}
//开发票
export function deploy(data) {
  return request({
    url: 'api/v1/deploy',
    method: 'post',
    data
  })
}

export function opsList(data) {
  return request({
    url: 'api/v1/opsList',
    method: 'post',
    data
  })
}

export function checkOps(data) {
  return request({
    url: 'api/v1/checkOps',
    method: 'post',
    data
  })
}

export function sgComputer(data) {
  return request({
    url: 'api/v1/sgComputer',
    method: 'post',
    data
  })
}

export function newsdeploy(data){
  return request({
    url:'apiOps/v1/deploy',
    method:'post',
    data
  })
}

export function newsdeploy2(data){
  return request({
    url:'apiOps/v1/deployLogList',
    method:'post',
    data
  })
}

export function newsdeployList(data) {
  return request({
    url: 'apiOps/v2/deployList',
    method: 'post',
    data
  })
}

//状态
export function deployStatus(data) {
  return request({
    url: 'apiOps/v1/deployStatus',
    method: 'post',
    data
  })
}
//保存状态
export function setDeployStatus(data) {
  return request({
    url: 'apiOps/v1/setDeployStatus',
    method: 'post',
    data
  })
}

//点击查看
export function getOpsStatus(data) {
  return request({
    url: 'apiOps/v1/getOpsStatus',
    method: 'post',
    data
  })
}

export function deployTax(data) {
  return request({
    url: 'apiOps/v1/deployTax',
    method: 'post',
    data
  })
}
